/* eslint-disable import/export */
import { isNull, first, castArray, map, get } from 'lodash';
import { formatMission as format } from '@/selectors/missionsCreator/common';
import contractTypeHandler from './helpers/contractTypeHandler';
import { getArea, getVisitDate } from '@/selectors/customFields';
import initialValues from '@/components/MissionForm/initialValues';
import config from '@/_config';

export * from './common';

export const formatMission = ({
  salary,
  contract_type,
  daily_rate,
  billings,
  budget,
  ...otherValues
}) => ({
  ...format({ ...otherValues, contract_type }),
  ...contractTypeHandler(contract_type, salary, daily_rate, billings, budget),
});

export const getEditableMission = ({
  contract_types,
  begin_at,
  end_at,
  end_months,
  limited_at,
  work_days,
  location,
  budget,
  nda_attachment,
  custom_fields,
  languages,
  optional_languages,
  job,
  company_level,
  daily_rate,
  salary,
  consultations,
  ...values
}) => {
  const visit_date = getVisitDate(custom_fields);
  return {
    ...values,
    contract_type: first(contract_types),
    begin_at: begin_at ? new Date(begin_at) : null,
    end_at: end_at ? new Date(end_at) : null,
    end_months: castArray(end_months || 0),
    limited_at: limited_at ? new Date(limited_at) : null,
    work_days: castArray(work_days),
    location_place: location,
    budget: budget || [
      config.company.budgetRate.min,
      config.company.budgetRate.max,
    ],
    daily_rate: daily_rate || [
      config.company.dailyRate.min,
      config.company.dailyRate.max,
    ],
    salary,
    nda_attachment: isNull(nda_attachment) ? [] : [nda_attachment],
    area: getArea(custom_fields),
    visit_date: visit_date ? new Date(visit_date) : null,
    languages_alpha2: map(languages, 'alpha2'),
    optional_languages_alpha2: map(optional_languages, 'alpha2'),
    name: get(job, 'name'),
    company_level: isNull(company_level)
      ? get(initialValues, 'company_level', null)
      : company_level,
    ...(config.mission.showConsultation && {
      // when we use config, we can avoid creating overrides
      consultations_attributes: consultations.map((consultation) => ({
        ...consultation,
        end_date: get(consultation, 'end_date')
          ? new Date(consultation.end_date)
          : null,
        consultation_lists_attributes: get(
          consultation,
          'consultation_lists',
          []
        )
          .map((consultation_lists) => get(consultation_lists, 'list.id'))
          .filter((id) => id), // remove empty value
      })),
    }),
  };
};
