import { ReactComponent as Zip } from '@/images/zip.svg';

export default [
  {
    label: 'fields.missions',
    value: 'missions',
    id: 'missions',
    checked: true,
    defaultChecked: 'missions',
    icon: Zip,
    'data-testid': 'missions',
  },
];
