import { compose, withProps } from 'recompose';
import { graphql } from 'react-apollo';
import { getCategoriesAssociatedWithSectors } from '@/api/Category/queries.gql';
import renderWhileLoading from '@/helpers/enhancers/renderWhileLoading';
import translate from '@/helpers/enhancers/translate';
import CategoriesSection from '@/components/CategoriesSection';
import { maxLengthValidator } from '@/helpers/company';
import config from '@/_config';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { PROFILE_ACTIVITIES } from '@/constants/forms';
import { SUB_CATEGORIES } from '@/constants/fields/subCategory.js';

export default compose(
  connect((state, props) => {
    const sectors = get(props, 'initialValues.sectors', []);
    const category_ids = get(props, 'initialValues.category_ids', []);
    const sub_categories = get(props, `initialValues.${SUB_CATEGORIES}`, []);
    return {
      sectors,
      category_ids,
      sub_categories,
    };
  }),
  withProps({
    page: PROFILE_ACTIVITIES,
    baseKey: 'company.modal.categories',
    baseName: '',
    validate: maxLengthValidator(
      config.validation.expertProfile.maxSubCategoriesLength,
      'sub_categories.error.too_many',
      SUB_CATEGORIES
    ),
    required: true,
  }),
  graphql(getCategoriesAssociatedWithSectors, { name: 'categories' }),
  renderWhileLoading(),
  translate()
)(CategoriesSection);
