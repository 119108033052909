export const requiredLegalInformation = [
  'banking_agency',
  'banking_holder_name',
  'bic',
  'billing_city',
  'billing_country',
  'billing_first_name',
  'billing_last_name',
  'billing_mail',
  'billing_phone',
  'billing_street',
  'billing_zipcode',
  'birth_date',
  'city',
  'corporate_name',
  'country',
  'country_of_residence',
  'description',
  'first_name',
  'gender',
  'iban',
  'last_name',
  'nationality',
  'siret',
  'street',
  'zipcode',
  'legal_form',
  'company_created_at',
  'rcs',
  'naf_ape',
  'n_1_revenues',
];
