import { MISSIONS } from '@/constants/contractTypes';
import config from '@/_config';

export default {
  company_level: [0, 30],
  work_days: [5],
  billings: null,
  daily_rate: [config.company.dailyRate.min, config.company.dailyRate.max],
  budget: [config.company.budgetRate.min, config.company.budgetRate.max],
  end_months: [6],
  attachments: [],
  nda_attachment: [],
  category_ids: [],
  sub_categories: [],
  skills: [],
  optional_skills: [],
  contract_type: MISSIONS,
  salary: [config.company.salary.min, config.company.salary.max],
  consultations_attributes: [],
};
