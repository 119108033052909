import { ReactComponent as Building } from '@/images/building.svg';
import { ReactComponent as Car } from '@/images/car.svg';

export default [
  {
    id: 'on_site',
    value: 'on_site',
    label: 'company.modal.modalities.mobility.onSite',
    icon: Building,
  },
  {
    id: 'remote_can_move',
    value: 'remote_can_move',
    label: 'company.modal.modalities.mobility.canMove',
    icon: Car,
  },
];
