/* eslint-disable import/export */
import { get, isEmpty } from 'lodash';
import { defaultFilters as getCommonFilters } from './common';
import { DEFAULT_POOL } from '@/constants/pool';

export * from './common';

export function defaultFilters(user) {
  const commonFilters = getCommonFilters();

  // consultations, filters according to client_pool_ids on mission
  const userClientPoolIds = get(user, 'company.client_pool_ids');
  const userClientPoolIdsRequestString = !isEmpty(userClientPoolIds)
    ? userClientPoolIds.reduce((acc, currentValue) => {
        if (!isEmpty(currentValue) && currentValue !== DEFAULT_POOL)
          return `${acc} OR current_consultation_pool:${currentValue}`;
        return acc;
      }, '')
    : '';

  return {
    ...commonFilters,
    experts: get(user, 'administrator')
      ? `${commonFilters.experts}`
      : `${commonFilters.experts} AND is_registered: true`,
    missions: `${
      commonFilters.missions
    } AND NOT status:stand_by AND (has_current_consultation:false OR current_consultation_pool:default${userClientPoolIdsRequestString})`,
  };
}
