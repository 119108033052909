import React, { useState, useEffect, useCallback } from 'react';
import MediaQuery from 'react-responsive';
import { BREAKPOINTS } from '@/constants/screen';
import { EDIT } from '@/constants/modes';
import {
  string,
  arrayOf,
  bool,
  oneOfType,
  array,
  func,
  number,
} from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import { RfHelper } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import { propType } from 'graphql-anywhere';
import categoryFragment from '@/api/Category/fragment';
import CategoriesField from '@/components/CategoriesSection/CategoriesField';
import { get, isEmpty } from 'lodash';
import InformationMessage from '@/components/InformationMessage';
import { change } from 'redux-form';
import { MISSION, POST_SIGNUP, PROFILE_ACTIVITIES } from '@/constants/forms';
import { SUB_CATEGORIES } from '@/constants/fields/subCategory';
import './styles.scss';

function CategoriesSection({
  baseKey,
  baseName,
  validate,
  required,
  sectors,
  categories,
  page,
  loading,
  setLoading,
  favoriteLimit,
  ...props
}) {
  const { t } = useTranslation();
  // synchronize data with request categories for the return of the prev page in post sign up
  const [data, setData] = useState({});
  const [bufferSectors, setBufferSectors] = useState(sectors);
  const categoriesField = `${baseName}category_ids`;
  const subcategoriesField = `${baseName}${SUB_CATEGORIES}`;

  const { category_ids, sub_categories } = props;
  const { dispatch } = props;

  // overwrite selects of categories and sub categories according to sectors selected
  // we keep only categories and sub cateogries available from the selections,
  // and remove the rest of selection
  const selectedCategories = useCallback(
    (valuesSelectedCategories) => {
      const categoriesIdDisplayed = () =>
        get(data, 'categories', false)
          ? data.categories.map((category) => category.id)
          : [];

      return valuesSelectedCategories.filter((value) =>
        categoriesIdDisplayed().includes(value)
      );
    },
    [data]
  );

  const selectedSubCategories = useCallback(
    (valuesSelectedSubCategories) => {
      const reduceCategoriesDisplayed = () =>
        data.categories.reduce((acc, category) => {
          acc.push(
            ...category.sub_categories.map((subCategory) => subCategory.id)
          );
          return acc;
        }, []);

      const subCategoriesIdDisplayed = () =>
        get(data, 'categories', false) ? reduceCategoriesDisplayed() : [];

      return valuesSelectedSubCategories.filter(({ id }) =>
        subCategoriesIdDisplayed().includes(id)
      );
    },
    [data]
  );

  useEffect(
    () => {
      const updateCatAndSubCatInForm = (clean = false) => {
        dispatch(
          change(
            props.form,
            categoriesField,
            // overwrite selections, keep only categories according to sectors
            clean ? [] : selectedCategories(category_ids)
          )
        );
        dispatch(
          change(
            props.form,
            subcategoriesField,
            // overwrite selections, keep only sub categories according to sectors
            clean ? [] : selectedSubCategories(sub_categories)
          )
        );
      };

      async function handleInitiateData() {
        setLoading(true);
        const dataCategories = await categories.refetch({ sectors });
        setData({
          // initiate data with properties of graphql object, and use later refresh function directly from data (cf. handleRefreshData())
          ...categories,
          // initiate list of categories if we are not in mode creation (form mission)
          ...((get(props, 'form') !== MISSION ||
            (get(props, 'form') === MISSION &&
              get(props, 'mode', false) !== EDIT)) &&
            get(dataCategories, 'data', false) && {
              categories: dataCategories.data.categories,
            }),
        });
        setLoading(false);
      }

      async function handleRefreshData() {
        setLoading(true);
        const dataCategories = await data.refetch({ sectors });
        setData({ ...data, categories: dataCategories.data.categories });
        updateCatAndSubCatInForm();
        setLoading(false);
      }

      function handleCleanData() {
        setData({ ...data, categories: [] });
        updateCatAndSubCatInForm(true);
        setLoading(false);
      }

      const sectorsChanged =
        JSON.stringify(bufferSectors) !== JSON.stringify(sectors);
      const dataNeedToBeSync =
        !get(data, 'categories', false) && get(categories, 'categories', false);
      const dataNeedToBeInitiate =
        !get(data, 'categories', false) ||
        get(data, 'categories', 0).length === 0;

      if (page === MISSION && !loading) {
        // creation/edit form mission part
        if (
          dataNeedToBeInitiate &&
          (!isEmpty(sectors) || get(props, 'form') !== MISSION)
        ) {
          handleInitiateData();
        } else if (sectorsChanged) {
          // new click on sectors
          if (isEmpty(sectors)) {
            // if no sectors selected, clean data
            handleCleanData();
          } else {
            handleRefreshData(); // else refresh query
          }
          setBufferSectors(sectors);
        }
      }

      if (page === POST_SIGNUP && !loading) {
        // new click on sectors Or return on page after pass on next step of post sign up (next, prev)
        if (sectorsChanged || dataNeedToBeSync) {
          if (isEmpty(sectors)) {
            // if no sectors selected, clean data
            handleCleanData();
          } else if (dataNeedToBeInitiate) {
            handleInitiateData();
          } else {
            handleRefreshData(); // else refresh query
          }
          setBufferSectors(sectors);
        }
      }

      if (page === PROFILE_ACTIVITIES && !loading) {
        if (isEmpty(data) && !isEmpty(sectors)) {
          // choice of categories in profile part
          handleInitiateData();
        }
      }
    },
    [
      bufferSectors,
      sectors,
      data,
      props,
      categories,
      loading,
      categoriesField,
      category_ids,
      dispatch,
      selectedCategories,
      selectedSubCategories,
      sub_categories,
      subcategoriesField,
      page,
    ]
  );

  const showCategories = () =>
    isEmpty(sectors) ||
    (!isEmpty(sectors) && !isEmpty(get(data, 'categories', false)));
  const showTitle = () =>
    page === MISSION || page === POST_SIGNUP ? showCategories() : true;

  return (
    <MediaQuery maxWidth={BREAKPOINTS.laptop}>
      {(mobile) => (
        <RfHelper values={[categoriesField, subcategoriesField]}>
          {(values) => (
            <div className={{ heightMin: '1424px' }}>
              <FormSection
                loading={loading}
                showTitle={showTitle()}
                title={t(`${baseKey}.title`)}
              >
                {isEmpty(get(data, 'categories', false)) && !loading
                  ? isEmpty(sectors) && (
                      <div>
                        <div>
                          <InformationMessage className="InformationMessage__banner">
                            <p>{t('company.section.categories.empty.text')}</p>
                          </InformationMessage>
                        </div>
                      </div>
                    )
                  : showCategories() && (
                      <CategoriesField
                        name={categoriesField}
                        subcategoriesFieldName={subcategoriesField}
                        dataCategories={data}
                        baseKey={baseKey}
                        mobile={mobile}
                        selectedCategories={selectedCategories(
                          values[categoriesField]
                        )}
                        selectedSubcategories={selectedSubCategories(
                          values[subcategoriesField]
                        )}
                        validate={validate}
                        required={required}
                        sectors={sectors}
                        favoriteLimit={favoriteLimit}
                        {...props}
                      />
                    )}
              </FormSection>
            </div>
          )}
        </RfHelper>
      )}
    </MediaQuery>
  );
}

CategoriesSection.propTypes = {
  baseKey: string.isRequired,
  baseName: string.isRequired,
  data: arrayOf(propType(categoryFragment)).isRequired,
  required: bool,
  validate: oneOfType([array, func]),
  getCategories: func.isRequired,
  page: string,
  favoriteLimit: number,
};

CategoriesSection.defaultProps = {
  required: false,
  validate: [],
  page: null,
  favoriteLimit: 3,
};

export default CategoriesSection;
