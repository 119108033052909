import { get } from 'lodash';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { getFormValues } from 'redux-form';
import CategoriesSection from './CategoriesSection';
import {
  SUB_CATEGORIES,
  COMPANY_SUB_CATEGORIES,
} from '@/constants/fields/subCategory.js';

export default compose(
  connect((state, props) => {
    const formValues = getFormValues('post-signup')(state);
    // Get selectedValues from the post-signup form to initiate the component properly when the user goes back in the form step
    const selectedCategoryIds = get(formValues, 'company_category_ids', []);
    const selectedSubCategoryIds = get(formValues, COMPANY_SUB_CATEGORIES, []);
    const sectors = get(
      props,
      'initialValues.sectors',
      get(props, 'sectors', [])
    );
    const category_ids =
      selectedCategoryIds.length > 0
        ? selectedCategoryIds
        : get(
            props,
            'initialValues.category_ids',
            get(props, 'category_ids', [])
          );
    const sub_categories =
      selectedSubCategoryIds.length > 0
        ? selectedSubCategoryIds
        : get(
            props,
            'initialValues.sub_categories',
            get(props, SUB_CATEGORIES, [])
          );
    return {
      sectors,
      category_ids,
      sub_categories,
    };
  })
)(CategoriesSection);
