import { get, isObject } from 'lodash';
import { formatCompany as format } from '@/selectors/postSignup/common';
import contractTypeHandler from '../helpers/contractTypeHandler';

export const formatCompany = (
  {
    company_salary,
    company_contract_types,
    company_salary_confidentiality,
    company_daily_rate,
    company_billings,
    job_name,
    company_job_name,
    ...otherValues
  },
  kind
) => ({
  ...format({ ...otherValues, company_contract_types, kind }),
  // the job_name is a select or a text input according to the company kind, but the api requires a string
  job_name: isObject(job_name) ? get(job_name, 'value') : job_name,
  // the company_job_name is a select for expert, but the api requires a string
  company_job_name: isObject(company_job_name)
    ? get(company_job_name, 'value')
    : company_job_name,
  ...contractTypeHandler(
    company_contract_types,
    company_salary,
    company_daily_rate,
    company_billings,
    company_salary_confidentiality,
    'company'
  ),
});
